import { useEffect, useCallback, useState } from 'react'
// routes
import { useNavigate, useSearchParams } from 'react-router-dom'
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hooks'
//
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { useBoolean } from 'src/hooks/use-boolean'
import { useAuthContext } from '../hooks'

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login(),
}

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export default function AuthGuard({ children }: Props) {
  const router = useRouter()
  const { authenticated, method } = useAuthContext()
  const [searchParams] = useSearchParams()

  const [checked, setChecked] = useState(false)

  const check = useCallback(() => {
    if (!authenticated) {
      const source = searchParams.get('source') ?? undefined
      const sourceRef = searchParams.get('source_ref') ?? undefined
      const trialCode = searchParams.get('trial_code') ?? undefined
      const queryParams = new URLSearchParams({
        returnTo: `${window.location.pathname}${window.location.search}`,
      })
      if (source) {
        queryParams.set('source', source)
      }
      if (sourceRef) {
        queryParams.set('source_ref', sourceRef)
      }
      if (trialCode) {
        queryParams.set('trial_code', trialCode)
      }
      const queryString = queryParams.toString()

      const loginPath = loginPaths[method]

      const href = `${loginPath}${queryString}`

      router.replace(href)
    } else {
      setChecked(true)
    }
  }, [authenticated, method, router, searchParams])

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!checked) {
    return null
  }

  return <FatalAuthErrorListener>{children}</FatalAuthErrorListener>
}

function FatalAuthErrorListener({ children }: { children: React.ReactNode }) {
  const { fatalError, logout } = useAuthContext()
  const showDialog = useBoolean(fatalError)
  const loading = useBoolean()
  const navigate = useNavigate()

  useEffect(() => {
    showDialog.setValue(fatalError)
  }, [fatalError, showDialog])

  const handleLogout = useCallback(async () => {
    loading.onTrue()
    await logout().finally(() => {
      loading.onFalse()
      navigate(paths.auth.jwt.login(window.location.pathname))
    })
  }, [loading, logout, navigate])

  return (
    <>
      {children}
      <Dialog open={showDialog.value} onClose={showDialog.onFalse} sx={{ p: 4 }}>
        <DialogTitle>Authentication Error</DialogTitle>
        <DialogContent>
          <DialogContentText>There was an error while trying to authenticate. Please login again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Login</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
